import Link from 'next/link'
import { useRouter } from 'next/router'
import { Trans, useTranslation } from 'next-i18next'
import { useEffect, useMemo, useState } from 'react'
import clsx from 'clsx'

import { ISubpageProps } from 'components/AdvertsPage/types'
import Spinner from 'components/Spinner'
import { IconLocation } from 'icons'

import StyledText from './components/StyledText'

const Subpage = ({ href, text, count, value, isMobile }: ISubpageProps) => {
  const { t } = useTranslation(['home_adverts'])
  const router = useRouter()
  const subpageQuery = router.query?.subpage as string
  const [showLoader, setShowLoader] = useState(false)

  useEffect(() => {
    if (href.includes(subpageQuery)) {
      setShowLoader(false)
    }
  }, [href, subpageQuery, text])

  const isHighlighted = useMemo(
    () =>
      href.includes(subpageQuery) ||
      (!subpageQuery && text == 'badges.allJobAds'),
    [href, subpageQuery, text]
  )

  return (
    <Link
      href={href}
      className={clsx(
        'flex w-fit items-center gap-2 bg-[#D8D8D826]',
        isMobile ? 'my-1' : 'my-4',
        (href.includes(subpageQuery) ||
          (!subpageQuery && text.includes('allJobAds'))) &&
          'cursor-default'
      )}
      onClick={() => {
        if (!subpageQuery && text.includes('allJobAds')) {
          setShowLoader(false)
        } else {
          setShowLoader(!href.includes(subpageQuery))
        }
        router.push(href)
      }}
    >
      <div className={clsx('flex gap-4 py-2 pr-1 pl-4', isMobile && 'text-sm')}>
        {!isMobile && (
          <IconLocation className={clsx(isHighlighted && 'text-red-550')} />
        )}
        <span>
          <Trans
            i18nKey={text}
            t={t}
            ns={'home_adverts'}
            components={{
              text: <StyledText className={'font-bold'} />
            }}
            values={{ value }}
          />
        </span>
      </div>
      <div
        className={clsx(
          'flex w-11 items-center justify-center bg-[#00000005] py-2 text-red-550'
        )}
      >
        {showLoader || !count ? <Spinner isVisible size={24} /> : count}
      </div>
    </Link>
  )
}

export default Subpage
